<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>

      <template #right>
        <Calendar
          placeholder="من"
          v-model="search.start"
          dateFormat="dd/mm/yy"
        />
        <Calendar
          style="margin-right: 9px;"
          placeholder="الى"
          v-model="search.end"
          dateFormat="dd/mm/yy"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-success"
          icon="pi pi-search"
          @click="searchAdd()" v-tooltip="'بحث'"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-help"
          icon="pi pi-download"
          @click="exportCSV($event)" v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      id="print"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه ملاحظات تسليم العملاء
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <!-- <Column field="id" header="رقم العقد" :sortable="true" /> -->
      <Column
        field="contractsId.clientsId.code"
        header="كود العميل"
        :sortable="true"
      />
      <Column
        field="contractsId.clientsId.name"
        header="اسم العميل"
        :sortable="true"
      />
      <Column
        field="contractsId.branchesId.name"
        header="اسم الفرع"
        :sortable="true"
      />
      <Column field="contractsId.date" header="تاريخ العقد" :sortable="true" />
      <Column field="contractsId.total" header="قيمه العقد" :sortable="true" />

      <Column
        field="id"
        header="تحويل الى اوامر التشغيل"
        v-if="$checkRoles('deliveryToInstalltion')"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            type="button"
            @click="
              backDelivery(
                slotProps.data.id,
                slotProps.data.installationsId.id,
                slotProps.data.contractsId.productsList,
                slotProps.data.backDeliveryNote,
              )
            "
            icon="pi pi-question"
            class="p-button-warning p-ml-2  p-button-rounded"
          ></Button>
        </template>
      </Column>

      <Column
        field="id"
        header="ارجاع للتسليم"
        v-if="$checkRoles('deliveryToDelivery')"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            type="button"
            @click="
              toAddDelivery(slotProps.data.id, slotProps.data.contractsId.id)
            "
            icon="pi pi-reply"
            v-if="!slotProps.data.toDelivery"
            class="p-button-info p-ml-2  p-button-rounded"
          ></Button>
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            class="p-datatable-customers table table-striped"
            :value="[slotProps.data]"
          >
            <template #header>
              قائمه الملاحظات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="contractsId.note" header="ملاحظات العقد" />
            <Column field="note" header="ملاحظات التسليم" />
            <Column field="backDeliveryNote" header="ملاحظات السكيرتاريه" />
          </DataTable>
          <DataTable
            :value="slotProps.data.contractsId.productsList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه المنتجات والخدمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />
            <Column field="usersId.name" header="الموظف المسئول" />

            <!-- <Column
              field="id"
              header="ارجاع الى التركيبات"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <InputSwitch v-model="slotProps2.data.taskBack" />
              </template>
            </Column>
            <Column
              field="id"
              header="ارجاع لموظف اخر"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <InputSwitch
                  :disabled="!slotProps2.data.taskBack"
                  v-model="slotProps2.data.taskBackUser"
                />
              </template>
            </Column> -->
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      displayBasic: false,
      expandedRows: [],
      loading: true,
      deliveryStepsList: [],
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    searchAdd() {
      if (this.search.start && !this.search.end) {
        const start = new Date(this.search.start).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() >= start;
        });
      }

      if (!this.search.start && this.search.end) {
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() <= end;
        });
      }

      if (this.search.start && this.search.end) {
        const start = new Date(this.search.start).getTime();
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return (
            new Date(item.createdAt).getTime() >= start &&
            new Date(item.createdAt).getTime() <= end
          );
        });
      }
    },
    backDelivery(id, installationsId, list, backDeliveryNote) {
      this.$http.delete(`delivery/${id}`).then(
        () => {
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم التحويل بنجاح',
            life: 3000,
          });

          this.getData();
          this.$http
            .put(`installations/${installationsId}`, {
              toDelivery: false,
              status: 'backDelivery',
              backDeliveryNote,
            })
            .then(() => {
              for (const it of list) {
                if (it.taskBack) {
                  let obj = {
                    status: null,
                    isReady: false,
                    distributionsDate: null,
                    revisionDate: null,
                    readyDate: null,
                  };
                  if (it.taskBackUser) {
                    obj.usersId = null;
                  }
                  this.$http.put(`productsList/` + it.id, obj).then(() => {});
                }
              }
              // this.$http
              //   .put(`productsList/byContractsId/${contractsId}`, {
              //     isReady: false,
              //     status: null,
              //   })
              //   .then(() => {});
            });
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    getData() {
      this.$http.get(`delivery/backDelivery2`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

 
 
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    this.getData();
  },
};
</script>
